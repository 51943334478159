/*Some special elements specified, nothing final yet*/

/*These width-styles are used to make the scroll bar on spikes-tables
not look totally awful*/

#spikes_table tbody {
  display:block;
  max-height:300px;
  overflow-y:scroll;
  }
  #spikes_table thead{
  display:table;
  width:100%;
  table-layout:fixed;
  }
  #spikes_table tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
  }

#dashboardTitle {
  background-color: whitesmoke;
}

/*"Piikki" & "Omat tiedot" text*/
#dashboardTab {
  color:black;
}

/*when clicked*/
#dashboardTab:active {
  color:black;
  background-color:white;
}

/*"Isäntä" text"*/
#hostUpperTab {
  color: black;
}

#hostUpperTab.active {
  color: black;
  background-color: lightgray;
  border-color: lightgray;
}

/*"Käyttäjät", "Tuotteet", "Maksukehote" text*/
#hostTab {
  color:black;
}

#hostTab:active {
  color:black;
  background-color:white;
}

/*"Aikajana", "Piikit", "Raha" text*/
#statsTab {
  color:black;
  background: white;
}

#statsTab.active {
  color: black;
  background: whitesmoke;
  border-color: whitesmoke;
}

/*Main page background*/
#tabInnards {
  background-color:white;
}

/*Stat page background*/
#statTabInnards {
  background-color:whitesmoke;
}

[class='custom-control-input']:checked ~ [class='custom-control-label']::before {
  background-color: #373433;
  border-color: #373433;
}

/*Regular buttons e.g. for spikes*/
.btn-dark {
  background-color: #373433;
  border-color: #373433;
}

.btn-outline-dark {
  color: #373433;
  border-color: #373433;
}

.btn-outline-dark:hover {
  background-color: #373433;
  border-color: #373433;
}

.btn-outline-dark:active {
  background-color: #373433 !important;
}

/*Buttons for deleting products, categories, users*/
.btn-danger {
  background-color: #ea7125;
  border-color: #ea7125;
}

.btn-danger:focus {
  background-color: darkred;
  border-color: darkred;
}

.btn-danger:hover {
  background-color: darkred;
  border-color: darkred;
}

.btn-danger:active {
  background-color: darkred;
  border-color: darkred;
}

/*Buttons for adding products, categories and saving dialogues*/
.btn-success {
  background-color: #5abbc0 ;
  border-color: #5abbc0 ;
}

.btn-success:hover {
  background-color: darkcyan ;
  border-color: darkcyan ;
}

.btn-success:focus {
  background-color: darkcyan ;
  border-color: darkcyan ;
}

.btn-success:active {
  background-color: darkcyan ;
  border-color: darkcyan ;
}

.btn-outline-light {
  background-color: #373433;
 /* border-color: #373433;*/
}

/*For controlling the ripple effect on spike buttons*/
.btn-ripple {
	display: inline-block;
	position: relative;
	overflow: hidden;
	transition: all ease-in-out .3s;
}

.btn-ripple::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 25%;
	height: 100%;
	width: 50%;
	background-color: #fff;
	border-radius: 50%;
	opacity: 0;
	pointer-events: none;
	transition: all ease-in-out .3s;
	transform: scale(5, 5);
}

.btn-ripple:active::after {
	padding: 0;
	margin: 0;
	opacity: 1;
	transition: 0s;
	transform: scale(0, 0);
}

  /*Regular notification color e.g. for spikes*/
  .Toastify__toast--dark {
    background-color: #373433;
  }

  /*This overrides the default toastify dark notification progress bar color
  (which is violet)*/
  .Toastify__progress-bar--dark {
    background-color: #a9a9a9;
  }

  /*Error notification color*/
  .Toastify__toast--error {
    background-color: #ea7125;
  }

  /*Success notification color*/
  .Toastify__toast--success {
      background-color: #5abbc0 ;
  }

    
/*These next 3 styles enable the visible-on-hover edit-icon on the 
User profile page.*/
/*NO LONGER IN USE*/

.hide {
  opacity: 0;
  transition: opacity 200ms 0ms;
}

.hoverEdit:hover {
  background-color: transparent !important;
}

.hoverEdit:hover + .hide {
  opacity: 1;
}